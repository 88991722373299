import { css } from 'styled-components'
import { rem, border } from 'polished'
import * as vars from '../settings/Variables'


const base = css`
  @import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,900|Source+Sans+Pro:300,400,600');
  
  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${vars.font.family.default};
    font-weight: ${vars.font.weight.default};
    line-height: ${vars.font.height.default};
    color: ${vars.colors.text};
  }

  header {
    padding-top: 4vw;
    margin-bottom: calc(2vw + 2rem);
    ${border('bottom', '2px', 'solid', '#EBEBEB')}
  }

  .container {
    max-width: ${vars.maxWidth};
    margin: 0 auto;
    padding: 0 ${vars.sizes.gutter};
  }
`

export default base