import React from 'react'
import Section from '../components/Section/Section'
import A from '../components/Text/Link'
import Image from '../components/Image/Image'

import * as vars from '../settings/Variables'

import { Grid, Col, Row } from 'react-styled-flexboxgrid'

import Face from '../components/Face/Face'

const Home = (props) => {
  function randomNumber(min, max) {
    return Math.floor(min + Math.random() * (max + 1 - min))
  }
  return (
    <>
      <div className="container">
        <Face />
        <Section h1="Jonno Riekwel" h2="User Interface Designer & Front-end Developer">

          <div className="columns is-centered">
            <div className="column is-7">
              <p>I'm a user interface designer with over 12 years of experience <em>(rougly 150+ projects)</em> working with startups, agencies and individuals, solving complex problems through simple design.</p>
              <p>I love designing and building high quality products.</p>
              <p><A href="#">Let's chat</A></p>
            </div>
          </div>
          
        </Section>
        <Section h1="Here’s some of" h2="My best (recent) work">
          <div className="columns is-mobile">
            <div className="column is-full-mobile is-half">
              <Image src="https://i.redd.it/2fvp7vkhwyx11.jpg" />
            </div>
            <div className="column is-full-mobile is-half">
              <Image src="https://i.redd.it/2fvp7vkhwyx11.jpg" />
            </div>
          </div>
        </Section>
        <Section h1="Have a listen to" h2="My favourite music">
          <div className="columns is-centered is-mobile">
            <div className="column is-half-mobile is-third-desktop">
              <Image src="/assets/music/deep.jpg" as="a" href="https://open.spotify.com/user/jonnotie/playlist/4TpUr2NyxVY4iLl8smSJjp?si=X0JEtm5ITMSServiplg3xQ" />
            </div>
            <div className="column is-half-mobile is-third-desktop">
              <Image src="/assets/music/chill.jpg" />
            </div>
            <div className="column is-half-mobile is-third-desktop">
              <Image src="/assets/music/smoke.jpg" />
            </div>
          </div>
        </Section>
      </div>
    </>
  );
}


 
export default Home;