import React from 'react';
import {Row, Column} from '../Grid/Columns'

const Section = (props) => {
  return (
    <section>
      <Row options="is-centered">
        <Column size="is-7">
          <header>
            <h1>{props.h1}</h1>
            <h2>{props.h2}</h2>
          </header>
        </Column>
      </Row>
      
      {props.children}
    </section>
  );
}

export default Section;