import React from 'react';

function Row(props) {
  return (
    <div className={`columns ${props.options}`}>
      {props.children}
    </div>
  )
}

function Column(props) {
  return (
    <div className={`column ${props.size}`}>
      {props.children}
    </div>
  )
}

export {Column, Row};