import React from 'react'

const Empty = (props) => {
  return (
    <>
      <div className="container">
        <p className="has-text-centered">Nothing to see here, move along</p>
      </div>
    </>
  );
}

export default Empty;