// REACT
import React from 'react';
import { Switch, Route} from 'react-router-dom'

// CSS
import { createGlobalStyle } from 'styled-components'
import base from './styles/Base'

import './styles/base.scss'


// VARS
import * as vars from './settings/Variables'

// PAGES
import Empty from './pages/Empty'
import Home from './pages/Home'

const GlobalStyle = createGlobalStyle`
  ${base}
`

function App() {
  return (
    <>
      <GlobalStyle />
      <main>
        <Switch>
          <Route exact path='/' component={Empty} />
          <Route exact path='/home' component={Home} />
          {/* <Route path='/images/:number' component={Images} /> */}
        </Switch>
      </main>
    </>
  )
}


export default App;
