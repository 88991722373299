// REACT
import React from 'react';

// PACKAGES
import { Parallax } from 'react-scroll-parallax';
import VisibilitySensor from 'react-visibility-sensor'

// CSS
import styled from 'styled-components'

// COMPONENTS
import Img from 'react-image';

// VARS
import * as vars from '../../settings/Variables'

function onChange(isVisible) {
  console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
}

function randomNumber(min, max) {
  return Math.floor(min + Math.random() * (max + 1 - min))
}

const StyledImage = styled.div`
  box-shadow: ${vars.shadow.default};
  display: inline-block;
  transition: all 0.3s ease;
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;

  &:hover {
    box-shadow: ${vars.shadow.hover};
    transform: scale(1.02);
    cursor: pointer;
  }
`

function Image(props) {
  return (
    <VisibilitySensor onChange={onChange}>
      <Parallax
        offsetYMax={randomNumber(15, 7)}
        offsetYMin={randomNumber(-15, -7)}
        slowerScrollRate
      >
        <StyledImage as={props.as} href={props.href} target="_blank">
          <Img src={props.src} />
        </StyledImage>
      </Parallax>
    </VisibilitySensor>
  )
}

export default Image;