import React, {useEffect, useState} from 'react';
import { animateScroll as scroll } from 'react-scroll'

import './Face.scss'

// const Photo = styled.div`
//   @media (max-width: 48rem) {
//     transform: scale(0.6);
//     transform-origin: top right;
//     top: 0;
//     right: 0;
//   }
// `


function Face(props) {
  const [classes, setClass] = useState("")

  function handleScroll() {
    const y = window.scrollY;

    if(y > 0) {
      setClass("has-scrolled")
    } else {
      setClass("")
    }
  }

  function handleClick() {
    scroll.scrollToTop()
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`Face ${classes}`} onClick={handleClick} />
  )
}

export default Face;