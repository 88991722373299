import { rem } from 'polished';

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 2, // rem
    outerMargin: 0, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76  // rem
    },
    breakpoints: {
      xs: 0,  // em
      sm: 48, // em
      md: 64, // em
      lg: 75  // em
    }
  }
}

const cols = {
  xs: 12,
  sm: 7,
  center: "sm"
} 

const colors = {
  base: "#000000",
  title: "#000000",
  subtitle: "#7F7F7F",
  text: "#313131",
  link: {
    default: "#77508C",
    hover: "#42205F"
  }
}

const sizes = {
  tiny: "8px",
  small: "16px",
  default: "24px",
  gutter: "24px",
  big: "32px",
  large: "40px"
}

const shadow = {
  default: "0 4px 28px 0 rgba(0,0,0,0.11)",
  hover: "0 4px 40px 0 rgba(0,0,0,0.3)"
}

const font = {
  size: {
    default: rem('24px'),
    large: rem('26px')
  },
  weight: {
    default: 400,
    large: 300,
    bold: 600
  },
  height: {
    default: 1,
    large: 1.2,
    paragraph: 1.5
  },
  family: {
    default: "Source Sans Pro",
    large: "Merriweather"
  }
}

const maxWidth = "1016px";

export {theme, cols, sizes, colors, font, shadow, maxWidth};