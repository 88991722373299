import React from 'react';

// CSS
import './Text.scss'

function Link(props) {
  return (
    <a className="Link" href="/">{props.children}</a>
  )
}

export default Link;